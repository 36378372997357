<template>
    <div class="storage-report">
        <b-card no-body class="d-print-none" style="margin-bottom: 0;">
            <b-card-header style="padding: 10px !important;">
                <h4 style="margin-top: 0.5rem; color: #73818f;">Выберите склад</h4>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col md="4">
                        <b-form-group description="Склад">
                            <storage-picker v-model="filters.storage"></storage-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group description="Поиск по номенклатуре">
                            <input v-model="filters.nomenclature" placeholder="Номенклатура" class="form-control">
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-checkbox v-model="filters.showLots" switch>Показывать партии</b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card v-for="(groupedItems, storage_id) in modifiedItems" style="margin-top: 10px;" class="table-responsive"
                :key="storage_id">
            <b-table small striped :responsive="true" hover :items="groupedItems" :fields="fields(storage_id)" caption-top
                     bordered>
                <template #cell(actions)="row">
                    <b-button variant="warning" size="sm" v-if="row.item.nomenclature_lot_id"
                              @click="openPrintModal(row.item.nomenclature_lot_id)">🖨
                    </b-button>
                </template>

                <template #head(transfer_to_storage)="row">
                    <div class="text-center" style="width:100%">
                        <b-checkbox
                            @click.native.stop
                            v-model="allTransfersToStorage"
                            @change="allToggleTransferToStorage"
                            :id="`all_transfer_to_storage_` + storage_id"
                            :name="`all_transfer_to_storage_` + storage_id"
                            :indeterminate="indeterminateTransfersToStorage"
                        ></b-checkbox>
                    </div>
                </template>

                <template #cell(transfer_to_storage)="row">
                    <b-form-checkbox-group
                        name="transfer_to_storage"
                        v-model="transfersToStorage"
                        :id="getTransferToStorageKey( storage_id, row.item )"
                    >
                        <b-form-checkbox :value="getTransferToStorageValue( row.item )"
                                         v-if="row.item.count > 0"></b-form-checkbox>
                    </b-form-checkbox-group>
                </template>

                <template #table-caption v-if="filters.storage">
                    <div style="display:inline-block">
                        <h4>{{ filters.storage.name }}</h4>
                    </div>
                    <div v-if="transfersToStorage.length > 0" style="display:inline-block">
                        <router-link
                            style="color:#fff;"
                            class="btn btn-danger"
                            :to="{ name: 'MaterialTransferActCreate', params: preparedDataForTransferToStorage }"
                        >Передать
                        </router-link>
                    </div>
                </template>

                <template #cell(nomenclatureLot.expiration_date)="row">
                    <template
                        v-if="row.item.nomenclature.useNomenclatureLot && row.item.nomenclatureLot && row.item.nomenclatureLot.expiration_date">
                        {{ moment(row.item.nomenclatureLot.expiration_date).format('DD.MM.YYYY HH:mm') }}
                        <i class="fas fa-exclamation text-warning"
                           v-show="isDateCloseToExpire(row.item.nomenclatureLot.expiration_date)"></i>
                        <i class="fas fa-exclamation-triangle text-danger"
                           v-show="isDateExpired(row.item.nomenclatureLot.expiration_date)"></i>
                    </template>
                </template>
            </b-table>
        </b-card>

        <b-modal
            centered
            hide-footer
            :id="`nicelabel-print-modal`"
            :ref="`nicelabel-print-modal`"
            @hide="actionsAfterHideModalNiceLabelCurrentPrint"
        >
            <div class="errors-output text-center" v-if="errorLabelsPrint !== null">
                <b>Ошибка: {{ errorLabelsPrint }}</b>
            </div>
            <div style="text-align:center;margin-top:20px;">
                <label style="margin-bottom:10px">Введите количество этикеток:</label>
                <input
                    type="number"
                    class="form-control"
                    placeholder="Введите количество"
                    v-model="numberOfRequiredLabels"
                />
            </div>
            <div style="margin-top:30px;text-align:right;">
                <button class="btn btn-primary" @click="niceLabelPrint">Отправить на печать</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import moment from 'moment';
    import {find, groupBy, sumBy, forEach, debounce} from 'lodash'
    import StoragePicker from "../_common/StoragePicker";
    import {PRINT_QUEUES, STORAGE_ENDPOINT} from '../../utils/endpoints';

    const TRANSFER_TO_STORAGE_PREFIX = 'transfer_to_storage_';

    const WITH = [
        'nomenclature',
        'nomenclature.measureUnit',
        'nomenclatureLot',
        'storage',
    ];

    export default {
        name: "StorageReportIndex",
        components: {StoragePicker},
        computed: {
            /**
             * Подготовленные данные для передачи
             */
            preparedDataForTransferToStorage() {
                return this.filters.storage && this.transfersToStorage.length ? {
                    transfer: {
                        storage: this.filters.storage,
                        transmissionItems: this.transfersToStorage
                    }
                } : {};
            },

            modifiedItems() {
                let itemsGroupedByStorage = groupBy( this.items, 'storage_id' )
                let storageId

                for ( storageId in itemsGroupedByStorage ) {

                    if ( !this.filters.showLots ) {
                        itemsGroupedByStorage[ storageId ] = Object.values( groupBy( itemsGroupedByStorage[ storageId ], 'nomenclature_id' ) ).map( nomenclatureItems => {
                            return {
                                ...nomenclatureItems[ 0 ],
                                nomenclature_name: nomenclatureItems[0].nomenclature.name,
                                count: sumBy( nomenclatureItems, i => parseFloat( i.count ) ),
                            }
                        } )
                    }

                    itemsGroupedByStorage[ storageId ] = itemsGroupedByStorage[ storageId ].map( item => {
                        if ( item.count < 0 ) {
                            item._rowVariant = 'text-danger'
                        }

                        item.nomenclature_name = item.nomenclature.name;

                        return item
                    } )
                }

                return itemsGroupedByStorage;
            },
            isFiltersFilled() {
                return this.filters.storageIds.length || this.filters.nomenclature;
            }
        },

        data() {
            return {
                filters: {
                    nomenclature: "",
                    storage: null,
                    showLots: true,
                },
                items: [],

                transfersToStorage: [],
                allTransfersToStorage: false,
                indeterminateTransfersToStorage: false,

                errorLabelsPrint: null,
                numberOfRequiredLabels: null,
                nomenclatureLotIds: [],
            }
        },
        watch: {
            'filters.nomenclature': debounce(function (val, vm) {
                this.updateData();
            }, 500),
            'filters.storage': function() {
                this.updateData();
            },

            /**
             * Отслеживаем изменения и устанвливаем флаги для полного и
             * частичного выбора элементов списка для передачи
             *
             * @param value
             */
            transfersToStorage( value ) {
                if ( value.length === 0 ) {
                    this.allTransfersToStorage = false;
                    this.indeterminateTransfersToStorage = false;
                } else if ( value.length === this.modifiedItems[ this.filters.storage.id ].length ) {
                    this.allTransfersToStorage = true;
                    this.indeterminateTransfersToStorage = false;
                } else {
                    this.allTransfersToStorage = false;
                    this.indeterminateTransfersToStorage = true;
                }
            }
        },
        methods: {
            moment,
            groupBy,
            find,
            niceLabelPrint() {
                let params = {
                    ids: this.nomenclatureLotIds,
                    duplicates: parseInt( this.numberOfRequiredLabels )
                };

                this.$http
                    .post( PRINT_QUEUES + '/append-to/nomenclature-lot', params )
                    .then(
                        () => {
                            this.$refs[ 'nicelabel-print-modal' ].hide();
                            this.actionsAfterHideModalNiceLabelCurrentPrint();
                        },
                        response => this.errorLabelsPrint =
                            response.body.errors && response.body.errors[ 0 ] ?
                                response.body.errors[ 0 ] : 'Произошла ошибка во время создания заданий на печать'
                    );
            },
            actionsAfterHideModalNiceLabelCurrentPrint() {
                this.errorLabelsPrint = null;
                this.numberOfRequiredLabels = null;
                this.nomenclatureLotIds = [];
            },
            openPrintModal( nomenclatureLotId ) {
                this.nomenclatureLotIds.push( nomenclatureLotId );
                this.$refs[ 'nicelabel-print-modal' ].show();
            },
            /**
             * Сбрасываем список элементов для передачи
             */
            resetTransferToStorage() {
                this.transfersToStorage = [];
                this.allTransfersToStorage = false;
                this.indeterminateTransfersToStorage = false;
            },
            /**
             * Добавляем/удаляем все элементы списка для передачи
             *
             * @param checked
             * @returns {null}
             */
            allToggleTransferToStorage( checked ) {
                if ( checked ) {
                    forEach( this.modifiedItems[ this.filters.storage.id ], function ( item ) {
                        this.transfersToStorage.push(
                            this.getTransferToStorageValue( item )
                        );
                    }.bind( this ) );

                    return null;
                }

                this.transfersToStorage = [];
            },
            /**
             * Получаем ключ элементы для передачи
             *
             * @param storageId
             * @param item
             * @returns {string}
             */
            getTransferToStorageKey( storageId, item ) {
                let key = storageId + '_' + item.nomenclature_id + '_' + item.nomenclature_lot_id;
                return TRANSFER_TO_STORAGE_PREFIX + key;
            },
            /**
             * Получаем значение элемента для передачи
             *
             * @param item
             * @returns {{count: *, nomenclatureId: *, nomenclatureLotId: *}}
             */
            getTransferToStorageValue( item ) {
                return {
                    count: parseFloat( item.count ).toFixed( 3 ),
                    nomenclature: { id: item.nomenclature_id },
                    nomenclatureLot: { id: item.nomenclature_lot_id }
                };
            },
            isDateExpired( date ) {
                return moment( date ) < moment()
            },
            isDateCloseToExpire( date ) {
                return !this.isDateExpired( date ) && moment( date ) < moment().add( 48, 'hours' )
            },
            purgeFilters() {
                this.filters = {
                    nomenclature: '',
                    storageIds: [],
                    showLots: true
                };
            },
            fields() {
                let fields = [];

                if ( this.filters.storage !== null && this.filters.showLots ) {
                    fields.push( {
                        key: 'transfer_to_storage',
                        label: '',
                        sortable: false,
                        tdClass: 'text-center'
                    } );
                }

                fields.push( {
                    key: "nomenclature_name",
                    label: "Номенклатура",
                    sortable: true,
                } );

                if ( this.filters.showLots ) {
                    fields.push(
                        {
                            key: "nomenclatureLot.id",
                            label: "Партия №",
                            sortable: false,
                            formatter( value, type, item ) {
                                return item.nomenclature.useNomenclatureLot ? value : ''
                            }
                        },
                        {
                            key: "nomenclatureLot.manufacture_date",
                            label: "Произведено",
                            sortable: true,
                            tdClass: 'text-center',
                            formatter( value, type, item ) {
                                return (
                                    item.nomenclature.useNomenclatureLot && value
                                ) ? moment( value ).format( 'DD.MM.YY HH:mm' ) : ''
                            }
                        },
                        {
                            key: "nomenclatureLot.expiration_date",
                            label: "Годен до",
                            sortable: true,
                            tdClass: 'text-center',
                        }
                    );
                }

                if ( !this.filters.storage || this.filters.storage.useAddressStorage ) {
                    fields.push( {
                        key: "storage_place",
                        label: "Ячейка",
                        sortable: false,
                    } )
                }

                fields.push( {
                    key: "count",
                    label: "Кол-во",
                    sortable: true,
                    tdClass: 'text-right',
                    formatter( count ) {
                        return parseFloat( count ).toFixed( 3 )
                    }
                } );

                fields.push( {
                    key: "nomenclature.measureUnit.name",
                    label: "Ед. изм.",
                    sortable: false,
                } );

                fields.push( {
                    key: 'actions',
                    label: 'Действия',
                    sortable: false,
                } );

                return fields;
            },
            updateData() {
                this.resetTransferToStorage();

                if ( !this.filters.storage ) {
                    return;
                }

                const filters = {
                    storageIds: [this.filters.storage.id],
                    searchString: this.filters.nomenclature,
                };

                this.$http.get(STORAGE_ENDPOINT + '/report', {
                    params: {
                        filters,
                        with: WITH
                    }
                }).then(response => {
                    this.items = response.data.data;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    }
                });
            },
        },
    }
</script>

<style>
    .errors-output {
        color: #900;
    }

    .storage-report .table-default > td:last-child {
        text-align: right;
    }

    .storage-report .table-text-danger {
        color: #dc3545 !important;
    }
</style>
